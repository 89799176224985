import React from "react";
import styled from "styled-components";


const Img = styled.img`
  height: 32px;
  width: 37px;
  padding-left: 30px;

  @media (max-width: 600px) {
    padding-left: 15px;
  }

  display: none;
  opacity: 0;
`;

export default function SettingsButton({ onClick }) {
  return (<></>
    // <div style={{
    //   display: "none",
    //   opacity: 1}}>
      // <Img src={settings} onClick={onClick} />
    // </div>
  );
}
