import React from 'react'
import './Error.css'
import RestoreIcon from '@mui/icons-material/Restore';

export default function error() {
  return (
    <>
      <div id="error">
        <div className="error">
          <div className="error-404">
            <div>
              <RestoreIcon sx={{
                height: "8rem",
                width: "auto",
                color: "rgb(0, 0, 0)",
                position: "absolute",
                transform: "rotate(-45deg)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "22px",
                left: "18px",
                "@media(max-width: 480px)": {
                  height: '20vw',
                  top: "4.5vw",
                  left: "3vw",
                }
              }} />
            </div>
          </div>
          <h2>Session Expired</h2>
          <p>The page you are looking for might have been expired</p>
          <a href="https://www.primas.net/">Contact</a>
        </div>
      </div>
    </>
  )
}
