import React, { useEffect } from 'react'
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { Flex } from 'components/shared/Flex';
import Timer from "./Timer";
import BeatingRate from './BeatingRate';
import { unit } from "constant/setting/VitalDescription";
import HeartRate from './HeartRate';
import TransitionText from './TransitionText';

const Box = styled(Flex)`
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 13.5rem;
    @media (max-width: 500px){
        height: 11rem;
    }
    @media (max-width: 320px){
        height: 8.5rem;
    }
    @media (max-width: 240px){
        height: 5.5rem;
    }
`;

const Wrapper = styled(Flex)`
    height: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 5px;
    background-color: #FFF;
    border-radius: 20px; 
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    flex-direction: column;
    @media (max-width: 500px){
        width: 94vw;
    }
    
    @media (max-width: 240px){
        border-radius: 10px;
    }
`;

const TopWrapper = styled(Flex)`
    height: 15%;
    justify-content: center;
    align-items: center;
`;

const MiddleWrapper = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px 20px;
    height: 60%;
    width: 90%;
    font-size: 18px;
    @media (max-width: 500px){
        font-size: 14px;
    }
    @media (max-width: 320px){
        padding: 0 2px;
        font-size: 9px;
    }
    @media (max-width: 240px){
        font-size: 7px;
    }
`;

const BottomWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 15%;
`;

const ValueWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2px;
`;

const Value = styled(Flex)`
  line-height: 100%;
  font-weight: 700;
  color: #000;
  font-size: 2.7vh;
`;

const Unit = styled(Flex)`
  color: #000;
  height: 15%;
  line-height: 100%;
  font-size: 1.5vh;
  position: relative;
  bottom: 1px;
`;

export default function MeasurePopup({ isMeasuring, durationSeconds, vitalSigns }: any) {
    const [checked, setChecked] = React.useState(isMeasuring);
    const containerRef = React.useRef(null);

    useEffect(() => {
        setChecked(isMeasuring);
    }, [isMeasuring])

    return (
        <Box ref={containerRef}>
            <Slide appear={checked} direction="up" timeout={350} in={checked} container={containerRef.current} style={{ height: "100%" }}>
                <Wrapper>
                    <TopWrapper>
                        <BeatingRate />
                        <ValueWrapper>
                            <Value>{vitalSigns.heartRate.isEnabled ? vitalSigns.heartRate.value || "--" : "--"}</Value>
                            <Unit>{unit["HR"]}</Unit>
                        </ValueWrapper>
                    </TopWrapper>

                    <MiddleWrapper>
                        <TransitionText isMeasuring={isMeasuring} />
                        <HeartRate />
                    </MiddleWrapper>

                    <BottomWrapper>
                        <Timer started={isMeasuring} durationSeconds={durationSeconds} />
                    </BottomWrapper>
                </Wrapper>
            </Slide>
        </Box>
    )
}