import axios from 'axios';
import { API_PATH } from 'constant/api/APISetting';
import { API_PARAM_KEY, FLAG_VALUE, SOURCE_FLAG } from 'constant/env/EnvKey';
import Encrypter from 'helpers/Cipher';
import { createLocalStorageStateHook } from 'use-local-storage-state';

const resolveDataKeyValue = (data: any): any => {
  const dataParse = JSON.parse(data);
  return Object.values(dataParse[0])[1];
};

export async function callAPIGetLicenseAndTime() {
  const encrypter = new Encrypter();
  var decode = null, encryptValue = null;
  var resBinahLicense: any = null, resBinahDuration: any = null;

  //VITALRPM
  if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
    resBinahLicense = await axios.get(`${API_PATH.getSettingByKeyRPM}/${API_PARAM_KEY.binahLicense}`);
  }
  //CALLFLOW
  else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
    resBinahLicense = await axios.get(`${API_PATH.getSettingByKey}/${API_PARAM_KEY.binahLicense}`);
  }

  encryptValue = resolveDataKeyValue(resBinahLicense.data.result);
  decode = encrypter.dencrypt(encryptValue);
  window["license"] = decode;

  //VITALRPM
  if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
    resBinahDuration = await axios.get(`${API_PATH.getSettingByKeyRPM}/${API_PARAM_KEY.binahDuration}`);
  }
  //CALLFLOW
  else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
    resBinahDuration = await axios.get(`${API_PATH.getSettingByKey}/${API_PARAM_KEY.binahDuration}`)
  }

  encryptValue = resolveDataKeyValue(resBinahDuration.data.result);
  decode = encrypter.dencrypt(encryptValue);
  window["time"] = decode;
  // window["time"] = 25;

  useLicenseKey = createLocalStorageStateHook('licenseKey', window["license"]);
  DEFAULT_MEASUREMENT_DURATION = window["time"];
  useMeasurementDuration = createLocalStorageStateHook('measurementDuration', DEFAULT_MEASUREMENT_DURATION);
}

export var useLicenseKey: any;
export var useMeasurementDuration: any;
export var DEFAULT_MEASUREMENT_DURATION: any;
export const MIN_MEASUREMENT_DURATION = 20;
export const MAX_MEASUREMENT_DURATION = 180;
export const useProductId = createLocalStorageStateHook('productId', null);