import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import monitor, {
  AlertData,
  DeviceOrientation,
  EnabledVitalSigns,
  FaceSessionOptions,
  Gender,
  HealthMonitorCodes,
  HealthMonitorSession,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from "@binah/web-sdk";
import { AppErrorCode, InfoType, InfoData } from "../types";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { API_PATH } from 'constant/api/APISetting';
import { UNKNOWN_DATA, VITALSIGN_DATE, VITALSIGN_KEY } from 'constant/setting/VitalConstraint';
import { FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";
import { PROFILE_DATA } from '../constant/setting/VitalConstraint';
import Helper from "helpers/Helper";


const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  processingTime: number,
  licenseKey: string,
  productId: string,
  startMeasuring: boolean
) => {
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [enabledVitalSigns, setEnabledVitalSigns] =
    useState<EnabledVitalSigns>();
  const [offlineMeasurements, setOfflineMeasurements] =
    useState<OfflineMeasurements>();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();

  const [error, setError] = useState<AlertData>({ code: -1 });
  const [warning, setWarning] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<InfoData>({ type: InfoType.NONE });

  const isDismissing = useRef<boolean>(false);

  const setInfoWithDismiss = useCallback(
    (info: InfoData, seconds?: number) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current],
  );

  const updateVitalSigns = useCallback((vitalSigns) => {
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    updateVitalSigns(vitalSignsResults.results);
  }, []);

  const onError = (errorData: AlertData) => {
    setError(errorData);
  };

  const onWarning = (warningData: AlertData) => {
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING
    ) {
      setVitalSigns(null);
    }
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING
    ) {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION,
      });
    } else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback((state: SessionState) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements: OfflineMeasurements) => {
      setOfflineMeasurements(offlineMeasurements);
    },
    []
  );

  const onActivation = useCallback((activationId: string) => {
    // the device has been activated with activationId
  }, []);

  const onFaceDetected = useCallback((isRect: boolean) => {
    if (!isRect) {
      setInfo({
        type: InfoType.INSTRUCTION,
        message: 'Face not detected',
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation,
          },
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e) {
        console.error("Error initializing HealthMonitor", e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey, productId]);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === SessionState.ACTIVE && session.terminate();
        var options: FaceSessionOptions = null;

        if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
          const dataResponse = await axios.get(`${API_PATH.getKeyPairValueRPM}/${token}`);
          var VitalsValue: {} = await JSON.parse(dataResponse.data.result);
          VitalsValue = Helper.convertJsonToLowercase(VitalsValue);

          if (VitalsValue && VitalsValue.hasOwnProperty(PROFILE_DATA.Age) && VitalsValue.hasOwnProperty(PROFILE_DATA.Weight) && VitalsValue.hasOwnProperty(PROFILE_DATA.Gender)) {
            var subjectDemographic = {
              age: Number.parseInt(VitalsValue[PROFILE_DATA.Age]),
              weight: Number.parseFloat(VitalsValue[PROFILE_DATA.Weight]),
              gender: Gender[`${(VitalsValue[PROFILE_DATA.Gender]).toUpperCase()}`],
            };
            // console.log(subjectDemographic);
            options = {
              input: video.current,
              cameraDeviceId: cameraId,
              processingTime,
              onFaceDetected,
              onVitalSign,
              onFinalResults,
              onError,
              onWarning,
              onStateChange,
              orientation: DeviceOrientation.PORTRAIT,
              subjectDemographic,
              /*******************************************************************************
               * For accurate vital signs calculation the user's parameters should be provided.
               * The following is an example of how the parameters are passed to the SDK:
               *
               * subjectDemographic: {age: 35, weight: 75, gender: Gender.MALE}
               *
               * When measuring a new user then a new session must be created using the
               * new user's parameters
               * The parameters are used locally on the device only for the vital sign
               * calculation, and deleted when the session is terminated.
               *******************************************************************************/
            };
          } else {
            options = {
              input: video.current,
              cameraDeviceId: cameraId,
              processingTime,
              onFaceDetected,
              onVitalSign,
              onFinalResults,
              onError,
              onWarning,
              onStateChange,
              orientation: DeviceOrientation.PORTRAIT,
            };
          }
        }

        options = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onFaceDetected,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error("Error creating a session", e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  const modifiedVitalSignsData = (vitalSigns: VitalSigns) => {
    var tempObj: {} = {};
    if (vitalSigns) {
      const bloodPressureToDisplay =
        vitalSigns.bloodPressure?.value?.systolic &&
          vitalSigns.bloodPressure?.value?.diastolic
          ? vitalSigns.bloodPressure?.value?.systolic +
          "/" +
          vitalSigns.bloodPressure?.value?.diastolic
          : "--";

      var tempObj: {} = {};
      tempObj[`${VITALSIGN_KEY.WellnessRange}`] = vitalSigns.wellnessIndex.value ? vitalSigns.wellnessIndex.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.HeartRate}`] = vitalSigns.heartRate ? vitalSigns.heartRate.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.BreathingRate}`] = vitalSigns.breathingRate ? vitalSigns.breathingRate.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.Stress}`] = vitalSigns.stressLevel ? vitalSigns.stressLevel.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.HRVariability}`] = vitalSigns.sdnn ? vitalSigns.sdnn.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.Parasympathetic_Activity}`] = vitalSigns.pnsIndex ? vitalSigns.pnsIndex.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.BloodPressure}`] = vitalSigns.bloodPressure ? bloodPressureToDisplay : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.Hemoglobin}`] = vitalSigns.hemoglobin ? vitalSigns.hemoglobin.value : UNKNOWN_DATA.NaN;
      tempObj[`${VITALSIGN_KEY.HemoglobinA1c}`] = vitalSigns.hemoglobinA1c ? vitalSigns.hemoglobinA1c.value : UNKNOWN_DATA.NaN;

      if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
        tempObj[`${VITALSIGN_DATE.DateTime}`] = new Date();
      }

    }
    return tempObj;
  };

  function modifiedRequestData(dataObj: any): any {
    var results: string = "";
    for (const [key, value] of Object.entries(dataObj)) {
      results = results.concat(`${key};${value};`);
    }

    //VITALRPM
    if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
      results = results.slice(0, -1);
    }
    //CALLFLOW
    else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
      results = results.concat(`Completed;true`);
    }

    return results;
  }

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      if (vitalSigns && window['Finished']) {
        async function callAPI() {
          const dataSending: {} = {
            ID: `3;${token}`,
            KeyPair: modifiedRequestData(modifiedVitalSignsData(vitalSigns)),
          };

          //VITALRPM
          if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
            await axios.post(`${API_PATH.addKeyPairValueRPM}`, dataSending);
            localStorage.setItem("refresh", "0");
            navigate("/vitalsign/result", { state: modifiedVitalSignsData(vitalSigns) });
          }
          //CALLFLOW
          else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
            await axios.post(`${API_PATH.addKeyPairValue}`, dataSending);
            localStorage.setItem("refresh", "0");
            navigate(`/vitalsign/review?token=${token}`, { state: modifiedVitalSignsData(vitalSigns) });
          }
        }

        try {
          callAPI();
        } catch (err) {
          console.log(err);
        }
      }
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  return {
    sessionState,
    vitalSigns: {
      heartRate: {
        value: vitalSigns?.heartRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledHeartRate,
      },
      breathingRate: {
        value: vitalSigns?.breathingRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledBreathingRate,
      },
      stress: {
        value: vitalSigns?.stressLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressLevel,
      },
      hrvSdnn: {
        value: vitalSigns?.sdnn?.value,
        isEnabled: enabledVitalSigns?.isEnabledSdnn,
      },
      // spo2: {
      //   value: null, //TODO Spo2 is currently disabled by algo
      //   isEnabled: false, //enabledVitalSigns?.isEnabledSpo2,
      // },
      spo2: {
        value: vitalSigns?.oxygenSaturation?.value,
        isEnabled: enabledVitalSigns?.isEnabledSpo2,
      },
      prq: {
        value: vitalSigns?.prq?.value,
        isEnabled: enabledVitalSigns?.isEnabledPrq,
      },
      bloodPressure: {
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      pns: {
        value: vitalSigns?.pnsZone?.value,
        isEnabled: enabledVitalSigns?.isEnabledPnsZone,
      },
      hemoglobin: {
        value: vitalSigns?.hemoglobin?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobin,
      },
      hemoglobinA1c: {
        value: vitalSigns?.hemoglobinA1c?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobinA1c,
      },
    },
    offlineMeasurements,
    error,
    warning,
    info,
  };
};

export default useMonitor;