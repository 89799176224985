import React from 'react'
import './Error.css'

export default function error() {
  return (
    <>
      <div id="error">
        <div className="error">
          <div className="error-404">
            <div></div>
            <h1>404</h1>
          </div>
          <h2>Page not found</h2>
          <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
          <a href="https://www.primas.net/">Contact</a>
        </div>
      </div>
    </>
  )
}
