import { STATUS_KEY, VITALSIGN_KEY } from "./VitalConstraint";
import HR_Icon from "../../assets/icon/result/heart-rate-unknown.svg";
import BR_Icon from "../../assets/icon/result/breathing-rate-unknown.svg";
import BP_Icon from "../../assets/icon/result/blood-pressure-unknown.svg";
import HRV_Icon from "../../assets/icon/result/hrv-unknown.svg";
import SL_Icon from "../../assets/icon/result/stress-unknown.svg";
import PNS_Icon from "../../assets/icon/result/recovery-unknown.svg";
import HG_Icon from "../../assets/icon/result/hemoglobin.svg";
import A1c_Icon from "../../assets/icon/result/a1c.svg";

export const unit = {
    HR: "BPM",
    BR: "BRPM",
    PRQ: "LEVEL",
    HRV: "MA",
    SpO2: "SpO2%",
    BP: "mmHg",
    PNS: "LEVEL",
    SL: "LEVEL",
    HG: "g/dL",
    A1c: "%",
}

export function descriptionTitle(vitalSign: any, status: string): any {
    var displayStatus = status == STATUS_KEY.UNKNOWN ? "--" : status;
    displayStatus = displayStatus.toUpperCase();
    switch (vitalSign) {
        case "HR": {
            return `Your Heart Rate is ${displayStatus}`
        }
        case "BR": {
            return `Your Breating Rate is ${displayStatus}`
        }
        case "PRQ": {
            return `Your Pulse Respiratory Quotient is ${displayStatus}`
        }
        case "SL": {
            return `Your Stress Level is ${displayStatus}`
        }
        case "SpO2": {
            return `Your SpO2 is ${displayStatus}`
        }
        case "BP": {
            return `Your Blood Pressure is ${displayStatus}`
        }
        case "PNS": {
            // return `Your Peripheral Nervous System is ${displayStatus}`
            return `Your Recovery Ability is ${displayStatus}`
        }
        case "HRV": {
            return `Your Heart Rate Variability is ${displayStatus}`
        }
        case "HG": {
            return `Your Hemoglobin is ${displayStatus}`
        }
        case "A1c": {
            return `Your Hemoglobin A1c is ${displayStatus}`
        }
        default:
            return "";
    }
}

export function fullName(vitalSign: any): any {
    switch (vitalSign) {
        case "HR": {
            return `Heart Rate`;
        }
        case "BR": {
            return `Breathing Rate`;
        }
        case "PRQ": {
            return `Pulse Respiratory Quotient`;
        }
        case "SL": {
            return `Stress Level`;
        }
        case "SpO2": {
            return `Oxygen Saturation`;
        }
        case "BP": {
            return `Blood Pressure`;
        }
        case "PNS": {
            return `Recovery Ability`;
        }
        case "HRV": {
            return `HR Variability`;
        }
        case "HG": {
            return `Hemoglobin`;
        }
        case "A1c": {
            return `Hemoglobin A1c`;
        }
        default:
            return "";
    }
}

export function description(vitalSign: any): any {
    switch (vitalSign) {
        case "HR": {
            return `<div>
            <div><span>The heart rate vital sign measures the number of times the heart beats per minute. The normal resting heart rate for a healthy adult is between 60-100 bpm. Think of the heart as a pump that pushes blood throughout the body. With every beat, the heart pumps blood containing oxygen and nutrients around the body and brings back waste products. A healthy heart supplies the body with the right amount of blood at a rate proportionate to whatever activity the body is undertaking.</span></div>
            <br />
            <div><span>Normal resting rates can differ between people. Furthermore, heart rates are lower when at rest and increase during exercise and can be influenced by factors like, weather, body position, emotions, body size, medication, and use of caffeine and nicotine.</span></div>
            <br />
            <div><span>At rest, a fast heart rate may indicate acute health conditions such as an infection, dehydration, stress, anxiety, thyroid disorder, shock, anemia, or certain heart conditions. Moreover, it can predict a long term risk for cardiovascular events. A low heart rate is common for people who exercise frequently and participate in athletics.</span></div>
            <br />
            <div><span>Tracking heart rate can provide insight into fitness levels, heart health, and emotional health. For individuals taking medication for cardiovascular conditions, daily heart rate measurements can assist the doctor in advising on the proper course of treatment.</span></div>
            </div>
            `;
        }
        case "BR": {
            return `<div>
            <div><span>Breathing rate is a vital sign that measures the number of breaths taken per minute. The normal breathing rate for adults at rest ranges between 12-20 breaths per minute, but may reach up to 24 breaths per minute for some older individuals.</span></div>
            <br />
            <div><span>When inhaling, oxygen enters the lungs and circulates to the various internal organs. When exhaling, carbon dioxide moves out of the body. The breathing rate plays a critical role in keeping the balance of oxygen and carbon dioxide even in the body. If the carbon dioxide level in the blood is high and the oxygen level in the blood is low, breathing rate increases.</span></div>
            <br />
            <div><span>Various factors affect breathing rates, including injuries, exercise, fever, anxiety, emotions, mood, alcohol, medication, metabolic issues, and medical conditions. A high or low rate might be the result of an activity, and therefore does not indicate that there is anything wrong.</span></div>
            <br />
            <div><span>Monitoring the breathing rate vital sign can assist the doctor in providing medical advice. Furthermore, Athletes may use respiratory rate as an objective indicator of how hard they are working. </span></div>
            </div>`;
        }
        case "PRQ": {
            return `<div><span>The pulse-respiration quotient (PRQ) reflects emergent properties of the complex interplay between cardiac and respiratory activities. The pulse-respiration quotient metric measures to what extent this interplay is functioning normally. A low or high score would indicate that your heart rate and/or breathing rate are disproportionate, which may indicate that both the heart and the lungs are working inefficiently.</span></div>`;
        }
        case "SL": {
            return `<div>
            <div><span>The sympathetic nervous system promotes the body&rsquo;s response to stress, commonly referred to as the &ldquo;fight or flight&rdquo; response. When preparing for an emergency, the sympathetic nervous system (SNS) activates numerous complex pathways and components. These physiological activities help to achieve a faster heart rate, breathing rate, and blood pressure. Noticeable changes include blood flow that moves away from the skin and stomach, and is redirected from the intestines to the brain, heart, and muscles, as well as sweating, &ldquo;goose-bumps&rdquo;, dilation of the pupils, and a host of other feelings that appear during the stress response.</span></div>
            <br />
            <div><span>The sympathetic stress metric uses HRV to calculate the magnitude of one&rsquo;s stress response, i.e., how stressed a person is. A low score would indicate a relaxed state, while a high score would suggest a stressed state.</span></div>
            </div>`;
        }
        case "SpO2": {
            return `<div>
            <div><span>Oxygen saturation (SpO2) is another vital sign monitored to evaluate a person&rsquo;s general health. Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Normal SpO2 for healthy lungs ranges between 95-100%. For individuals with chronic conditions or lung diseases, it could be lower than 95%.</span></div>
            <br />
            <div><span>A low level of oxygen in the blood is called hypoxemia. Typically, an oxygen saturation level lower than 90% is considered hypoxemia, which can be caused by chronic pulmonary diseases (COPD, COVID-19, Asthma, Lung Fibrosis, pulmonary hypertension), heart failure, sleep apnea, anemia, and high-altitude exposure (insufficient oxygen in the air) and medications that suppress breathing control. Common symptoms of hypoxemia include headache, rapid heart rate, coughing, shortness of breath, wheezing, confusion, and blueness of the skin and mucus membranes (cyanosis). </span></div>
            <br />
            <div><span>Oxygen saturation levels can also be used by athletes to understand whether a decrease in performance is a result of altitude changes or ability.</span></div>
            </div>`;
        }
        case "BP": {
            return `<div>
            <div><span>Blood pressure is a critical vital sign that measures the pressure of circulating blood against artery walls, and it is measured in two numbers. The first number, or systolic pressure, refers to the pressure inside the artery when the heart contracts and pumps blood throughout the body. The second number, or diastolic pressure, refers to the pressure inside the artery when the heart is at rest and is filling with blood.</span></div>
            <br />
            <div><span>Most people don&rsquo;t know if they have high blood pressure &ndash; especially since there may be no noticeable warning signs or symptoms &ndash; and therefore the blood pressure must be monitored.</span></div>
            <br />
            <div><span>High blood pressure (hypertension), which is a major risk factor for health problems such as heart disease, heart attack, and stroke. In most cases, high blood pressure has no defined cause, and it is called primary hypertension. However, it is related to unhealthy lifestyles such as physical inactivity, stress, obesity, shift-work, pregnancy, etc. It should be emphasized that blood pressure can be managed through diagnosis, lifestyle changes, medication and long-term monitoring.</span></div>
            <br />
            <div><span>Blood pressure is categorized as low, normal, or elevated: Low blood pressure is a systolic of less than 100, normal blood pressure is a systolic of 100 to 129, while elevated blood pressure is systolic of 130 or higher.</span></div>
            </div>`;
        }
        case "PNS": {
            return `<div>
            <div><span>The parasympathetic nervous system (PNS) regulates the body&rsquo;s &ldquo;rest and digest&rdquo; response and plays an important role in alleviating stress and promoting recovery. It does so by inhibiting the activity of the sympathetic nervous system and the release of stress hormones, and returning bodily functions to their resting state by slowing the heart rate, lowering blood pressure, reducing muscle tension and restoring regular breathing, digestion and glandular activity.</span></div>
            <br />
            <div><span>The parasympathetic metric measures the activity of the PNS and indicates how capable a person is of relaxing or recovering after a stressful occurrence. A low score would indicate a stressful state, while a high score would suggest a relaxed one.</span></div>
            </div>`;
        }
        case "HRV": {
            return `<div>
            <div><span>A healthy heart does not beat at regular intervals as would a metronome; rather, there are variations in time between beat to beat. These variations are measured by the Heart Rate Variability (HRV) metric and they reflect the functions of the parasympathetic and sympathetic nervous systems, the two components of the Autonomic Nervous System (ANS), which controls heart activity. </span></div>
            <br />
            <div><span>The sympathetic system is activated when the body is under stress, causing the heart to beat faster and more regularly, and causing HRV to decrease. The parasympathetic system manages the heart&rsquo;s activity to help the body reach a relaxed state and to recover from a stressful event. This relaxation response results in a slower and less regular heartbeat and is &nbsp;indicated by a higher HRV. </span></div>
            <br />
            <div><span>High levels of HRV generally indicate aerobic and general fitness. Athletes may track HRV to adjust their training program. They can learn when the body is being overworked, which often results in a drop in HRV and to learn how fast they recover. </span></div>
            </div>
            `;
        }
        case "HG": {
            return `<div>
            <div><span>Hemoglobin -  is a protein in a person’s red blood cells that carries oxygen to the human body's organs and tissues and transports carbon dioxide from your organs and tissues back to your lungs.</span></div>
            <br />
            <div><span>Hemoglobin, perhaps the best studied of all macromolecules, has not revealed all its secrets even at the clinically relevant levels, to say nothing of biophysical studies at the levels of its atoms and electrons. In recent years, although unexpected new functions have been found, the central goal of most biomedical hemoglobin research has been the development of a mechanistic description of the developmental control of the α- and β-globin gene clusters.</span></div>
            <br />
            <div><span>This field of research has been of great interest to those interested in the whole range of hemoglobin studies—from the most basic molecular genetics, to various “translational” models, to clinical problems in treating patients. It has been the hope that understanding these control mechanisms would lead to the discovery or design of drugs to treat the genetic hemoglobin diseases by efficient elevation of fetal hemoglobin and would also improve the efficiency of stem cell and gene transfer approaches to therapy.</span></div>
            <br />
            <div><span>Although some of these therapies have progressed greatly during this period, we are still far from understanding the basic processes controlling developmental changes in the globin gene clusters. Despite the enormous body of experimental data obtained from cell, animal, and clinical studies, no predictive model has yet been proposed to explain the control of this obviously complex system.</span></div>
            </div>`;
        }
        case "A1c": {
            return `<div>
            <div><span>Hemoglobin A1C - represents the average blood glucose (sugar) level for the last two to three months. HbA1c is measured in percentage with resolution up to 0.01%</span></div>
            <br />
            <div><span>Hemoglobin is a protein only found in red blood cells. In fact, hemoglobin is what gives blood its bright red coloring. Since red blood cells live about an average of three months, the A1c test will reflect those red blood cells that are present in the bloodstream at the time of the test; this is why the A1c serves as an average of blood sugar control.</span></div>
            <br />
            <div><span>The main job of hemoglobin is to carry oxygen from the lungs to all the cells of the body. Hemoglobin becomes glycated or coated with glucose from the bloodstream. The amount of glucose that is present in the blood will attach to the hemoglobin protein, and increased glucose levels will reflect on the surface of the hemoglobin protein, thereby rendering a higher A1c level.</span></div>
            </div>`;
        }
        default:
            return "";
    }
}

export function statusDescription(vitalSign: any) {
    if (vitalSign === "HR") {
        return `Normal range: 60 - 100`;
    } else if (vitalSign === "BR") {
        return `Normal range: 12 - 20`;
    } else if (vitalSign === "PRQ") {
        return `Normal range: 4 - 5`;
    } else if (vitalSign === "SL") {
        return `Normal level is 1-3`;
    } else if (vitalSign === "SpO2") {
        return `Normal range: 95% - 100%`;
    } else if (vitalSign === "BP") {
        return `Systolic < 120 Diastolic < 80`;
    } else if (vitalSign === "PNS") {
        return `Normal level: above 1 and below 3`;
    } else if (vitalSign === "HRV") {
        return `Normal range: above 49`;
    } else if (vitalSign === "HG") {
        return `Normal range: 9 - 17`;
    } else if (vitalSign === "A1c") {
        return `Normal range: 3 - 6`;
    }
}

export const MeasuringText: string[] = [
    "Stay still during the measurement",
    "Avoid moving or talking",
    "Stay still for accurate results",
    "Wait until the end for the best results",
    "Results in a few seconds, hold on",
    "Avoid distractions and stay focused",
    "Patience is key for accurate results",
    "Hold still, just a little bit longer",
    "Almost done",
    "Hold on, all the results will appear in a few seconds...",
];

export const ErrorCodeDetail = {
    /* Camera errors */
    "1001": "CAMERA CODE NO CAMERA ERROR",
    "1002": "CAMERA CODE CAMERA OPEN ERROR",
    "1005": "CAMERA CODE CAMERA MISSING PERMISSIONS ERROR",

    /* Measurement errors */
    "3003": "MEASUREMENT CODE MISDETECTION DURATION EXCEEDS LIMIT ERROR",
    "3004": "MEASUREMENT CODE INVALID RECENT DETECTION RATE ERROR",
    "3006": "MEASUREMENT CODE LICENSE ACTIVATION FAILED ERROR",
    "3008": "MEASUREMENT CODE INVALID MEASUREMENT AVERAGE DETECTION RATE ERROR",
    "3009": "MEASUREMENT CODE TOO MANY FRAMES INORDER",

    /* Measurement warnings */
    "3500": "MEASUREMENT CODE MISDETECTION DURATION EXCEEDS LIMIT WARNING",
    "3504": "MEASUREMENT CODE UNSUPPORTED ORIENTATION WARNING",
    "3505": "MEASUREMENT CODE INVALID RECENT FPS RATE WARNING",
    "3506": "MEASUREMENT CODE MEASUREMENT MISPLACED FRAME WARNING",
}


export function getIcon(vitalSign: string, status: string): any {
    switch (vitalSign) {
        case VITALSIGN_KEY.HeartRate: {
            return HR_Icon;
        }
        case VITALSIGN_KEY.BreathingRate: {
            return BR_Icon;
        }
        case VITALSIGN_KEY.Pulse_Respiration_Quotient: {
            return "";
        }
        case VITALSIGN_KEY.Stress: {
            return SL_Icon;
        }
        // case VITALSIGN_KEY.Oxygen_Saturation: {
        //     return SpO2_Icon;
        // }
        case VITALSIGN_KEY.BloodPressure: {
            return BP_Icon;
        }
        case VITALSIGN_KEY.Parasympathetic_Activity: {
            return PNS_Icon;
        }
        case VITALSIGN_KEY.HRVariability: {
            return HRV_Icon;
        } 
        case VITALSIGN_KEY.Hemoglobin: {
            return HG_Icon;
        }
        case VITALSIGN_KEY.HemoglobinA1c: {
            return A1c_Icon;
        }
        default:
            return "";
    }
}
