import axios from "axios";
import TopBar from "components/VitalMeasuringScreen/TopBar";
import { Flex } from "components/shared/Flex";
import VitalSignCard from "components/VitalResultCard/VitalSignCard";
import { API_PATH } from "constant/api/APISetting";
import { PROFILE_DATA, STATUS_KEY, VITALSIGN_DATE, VITALSIGN_KEY, VitalElementArray, rangeCheckingVitalSign } from "constant/setting/VitalConstraint";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProgressBar from "components/VitalResultCard/ProgressBar";
import BottomBar from "components/shared/BottomBar";
import Helper from "helpers/Helper";
import { FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";
import IsabelResult from '../../components/IsabelResult/IsabelResult';

const Wrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-family: Rubik,Segoe UI,sans-serif;
  overflow: auto;
`;

const Bottom = styled(Flex)`
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Middle = styled(Flex)`
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  width: 25.5rem;

  @media (max-width: 480px){
    width: 95%;
  }

  @media (max-width: 320px){
    width: 90%;
  }
`;

const CardWrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 30rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Title = styled(Flex)`
  width: 25.5rem;
  height: 6%;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.7vh;
  font-weight: 700;
  margin-top: 10px; 
  padding-left: 10px; 
  align-self: center;
  justify-self: center;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const SubTitle = styled(Flex)`
  margin: 10px 0 0 0;
  width: 25.5rem;
  font-size: 2.7vh;
  font-weight: 700;
  padding-left: 10px; 
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const SmallTitle = styled(Flex)`
  margin: 5px 0 0 0;
  width: 25.5rem;
  font-size: 1.75vh;
  /* font-weight: 00; */
  padding-left: 10px; 
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const Time = styled(Flex)`
`;

const Day = styled(Flex)`
`;

const TimeDay = styled(Flex)`
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 4%;
  color: #7a8395;
  gap: 5px;
  @media (max-width: 320px){
    font-size: 0.6rem;
  }
  @media (max-width: 240px){
    font-size: 0.48rem;
  }
`;

const ProfileDetail = styled(Flex)`
  width: 10.5rem;
  align-self: flex-start;
  padding: 10px;
  gap: 10px;
  flex-direction: column;
  @media (max-width: 320px){
    padding: 15px 10px 10px;
  }
  @media (max-width: 240px){
    gap: 5px;
  }
`;

const Information = styled(Flex)`
  font-size: 1.05rem;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 480px){
    font-size: 1rem;
  }
  @media (max-width: 320px){
    font-size: 0.68rem;
  }
  @media (max-width: 240px){
    font-size: 0.58rem;
  }
`;
const IsabelWrapper = styled(Flex)`
  width: 25rem;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
  @media (max-width: 480px){
    width: 100%;
  }
`;

const IsabelBox = styled(Flex)`
  padding: 10px 0;
  height: 13.25rem;
  width: 100%
  padding: 10px 0 10px 0;
  /* border: 1px solid red; */
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
  border-radius: 10px;

  @media (max-width: 480px){
    width: 21rem;
    height: 12rem;
  }

  @media (max-width: 375px){
    width: 21rem;
    height: 10rem;
  }

  @media (max-width: 320px){
    width: 17rem;
    height: 7rem;
  }
  
  @media (max-width: 240px){
    margin: 2.5px;
    width: 11rem;
    height: 5rem;
  }
`;

export default function Review() {
  const [result, setResult] = useState();
  const [wellness, setWellness] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [datetime, setDatetime] = useState({});
  const [isabelConditions, setIsabelConditions] = useState({});

  useEffect(() => {
    const getProfileData = async () => {
      const token = localStorage.getItem("token");
      var dataResponse = null;
      if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
        dataResponse = await axios.get(`${API_PATH.getKeyPairValueRPM}/${token}`);
      } else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
        dataResponse = await axios.get(`${API_PATH.getKeyPairValue}/${token}`);
      }

      var VitalsHistoryValue = await JSON.parse(dataResponse?.data?.result);

      setIsabelConditions(JSON.parse(decodeURIComponent(VitalsHistoryValue.conditions)));
      console.log(VitalsHistoryValue);
      if (VitalsHistoryValue) {
        const objConverted = Helper.convertJsonToLowercase(VitalsHistoryValue);
        var userDataObj = {};
        userDataObj[PROFILE_DATA.Gender] = objConverted[PROFILE_DATA.Gender];
        userDataObj[PROFILE_DATA.Age] = objConverted[PROFILE_DATA.Age];
        userDataObj[PROFILE_DATA.Pregnant] = objConverted[PROFILE_DATA.Pregnant];
        userDataObj["symptoms"] = objConverted["symptoms"]
        setProfileData(userDataObj);

        setDatetime(VitalsHistoryValue[VITALSIGN_DATE.DateTime])

        // resolve data get the key and value
        var greenObj: any = {};
        var redObj: any = {};
        var unknownObj: any = {};
        var tempObj: any = {};
        var age: number;
        if (Object.keys(profileData).length != 0) {
          age = parseInt(userDataObj[PROFILE_DATA.Age]);
        } else {
          age = null;
        }
        Object.entries(VitalsHistoryValue).map((item) => {
          if (VitalElementArray.includes(item[0])) {
            if (item[0] !== VITALSIGN_KEY.WellnessRange) {
              if (age) {
                if (rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.NORMAL || rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.MEDIUM) {
                  greenObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.HIGH || rangeCheckingVitalSign(item[0], item[1], age) == STATUS_KEY.LOW) {
                  redObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.UNKNOWN) {
                  unknownObj[`${item[0]}`] = item[1];
                }
              } else {
                if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.NORMAL || rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.MEDIUM) {
                  greenObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.HIGH || rangeCheckingVitalSign(item[0], item[1]) == STATUS_KEY.LOW) {
                  redObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.UNKNOWN) {
                  unknownObj[`${item[0]}`] = item[1];
                }
              }
            } else {
              var wellnessObj = [];
              wellnessObj.push(item[1]);
              wellnessObj.push(rangeCheckingVitalSign(item[0], item[1]));
              setWellness(wellnessObj);
            }
          }
        });
        tempObj = { ...redObj, ...greenObj, ...unknownObj };
        setResult(tempObj);
      } else {
        localStorage.setItem("profileData", null);
      }
    };
    getProfileData();
  }, []);

  return (
    <>
      <TopBar onSettingsClick={""} isMeasuring={false} isLoading={false} handleButtonClick={false} isShown={false} />
      <Wrapper>
        <Title>Wellness Overall Status</Title>
        <Middle>
          <ProgressBar wellnessRange={Number.parseInt(wellness[0])} wellnessRangeStatus={wellness[1]} />
          <ProfileDetail>
            <TimeDay>
              <Time>{datetime ? new Date(datetime.toString()).toLocaleTimeString() : "time"}</Time><span> • </span>
              <Day>{datetime ? new Date(datetime.toString()).toLocaleDateString() : "date"}</Day>
            </TimeDay>
            {Object.keys(profileData).length !== 0 &&
              <>
                <Information>{`Sex: ${profileData[PROFILE_DATA.Gender] ? Helper.displayGender(profileData[PROFILE_DATA.Gender]) : ""}`}</Information>
                <Information>{`Age: ${profileData[PROFILE_DATA.Age] ? profileData[PROFILE_DATA.Age] : ""}`}</Information>
                <Information>{`Pregnant: ${profileData[PROFILE_DATA.Pregnant] ? Helper.displayPregnant(profileData[PROFILE_DATA.Pregnant]) : ""}`}</Information>
              </>
            }
          </ProfileDetail>
        </Middle>
        <SubTitle>Vital Signs Result</SubTitle>
        <Bottom>
          <CardWrapper>{result && Object.entries(result).map((item, index) => <VitalSignCard key={index} vitalSign={item[0]} data={item[1]} />)}</CardWrapper>
          {
            (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) &&
            <>
              <SubTitle>Possible Conditions</SubTitle>
              <SmallTitle>Symptoms: {profileData["symptoms"]}</SmallTitle>
              <IsabelWrapper>
                <IsabelBox>
                  {
                    Object.entries(isabelConditions).map((item, index) =>
                      <IsabelResult condition={item[1]} />
                    )
                  }
                </IsabelBox>
              </IsabelWrapper>
            </>

          }
        </Bottom>
        <BottomBar />
      </Wrapper>
    </>
  );
}