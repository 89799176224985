import React from "react";
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import GlobalStyle from "./style/global"
import { BrowserRouter } from "react-router-dom";
import { callAPIGetLicenseAndTime } from "hooks/useLicenseDetails";
import App from "App";
import { Flex } from "components/shared/Flex";
import { ENV_VALUE, FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";
import { SOURCE_PARAM } from './constant/env/EnvKey';

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: #F7F9FF;
  flex-direction: column;
  align-items: center;
`;

console.log(ENV_VALUE.version);

const getTokenValidation = () => {
  const params = new URLSearchParams(window.location.search);
  if (
    (window.location.href.search("vitalsign") != -1 && window.location.href.search("result") == -1)
    || window.location.href.search("vitalsign/result") == -1
  ) {
    window['Finished'] = false;
    localStorage.clear();

    const token_bypass = params.get(SOURCE_PARAM.tokenParam) ? params.get(SOURCE_PARAM.tokenParam) : null;
    localStorage.setItem(SOURCE_PARAM.tokenParam, token_bypass);

    const is_iframe = params.get(SOURCE_PARAM.iframeParam) ? params.get(SOURCE_PARAM.iframeParam) : null;
    localStorage.setItem(SOURCE_PARAM.iframeParam, is_iframe);

    if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
      const token_testmode = params.get(process.env.REACT_APP_BYPASS_CHECKING_KEY) ? params.get(process.env.REACT_APP_BYPASS_CHECKING_KEY) : null;
      localStorage.setItem(process.env.REACT_APP_BYPASS_CHECKING_KEY, token_testmode);
    }
  }
};

const initiate = async () => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  getTokenValidation();
  await callAPIGetLicenseAndTime();

  root.render(
    <Wrapper>
      <GlobalStyle />
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Wrapper>
  );
};

initiate();