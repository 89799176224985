import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";

const LoadingWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Message = styled.h2`
  font-size: 18px;
  color: #01061b;
  font-weight: normal;
`;

export default function Loader() {
  return (
    <LoadingWrapper>
      <Message>{"Loading..."}</Message>
    </LoadingWrapper>
  );
}
