import { FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";

const Helper: any = {};

Helper.modifiedRequestData = (dataObj: any) => {
    var results: string = "";

    if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
        results = results.concat(`StartMeasure;true;`);
    }

    for (const [key, value] of Object.entries(dataObj)) {
        results = results.concat(`${key};${value};`);
    }

    //VITALRPM
    if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
        results = results.slice(0, -1);
    }
    //CALLFLOW
    else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
        results = results.concat(`Completed;true`);
    }

    return results;
}

Helper.getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

Helper.getRandomFloat = (min: number, max: number, fixed: number) => {
    return (Math.random() * (max - min) + min).toFixed(fixed); // The maximum is exclusive and the minimum is inclusive
}

Helper.randomVitalSignData = {
    HR: `${Helper.getRandomInt(50, 120)}`,
    BP: `${Helper.getRandomInt(110, 130)}/${Helper.getRandomInt(70, 90)}`,
    BR: `${Helper.getRandomInt(11, 36)}`,
    HRV: `${Helper.getRandomInt(40, 110)}`,
    PNS: `${Helper.getRandomInt(1, 5)}`,
    SL: `${Helper.getRandomInt(1, 5)}`,
    WR: `${Helper.getRandomInt(1, 10)}`,
    HG: `${Helper.getRandomFloat(6, 20, 1)}`,
    A1c: `${Helper.getRandomFloat(1, 8, 2)}`,
    // HR: `101`,
    // BP: `121/81`,
    // BR: `21`,
    // HRV: `49`,
    // PNS: `1`,
    // SL: `4`,
    // WR: `4`,
    // HG: `8.9`,
    // A1c: `6.1`,
};

Helper.upperFirstLetter = (data: string) => {
    if (data) {
        return data[0].toUpperCase() + data.substring(1);
    }
}

Helper.convertJsonToLowercase = (obj: any): any => {
    var key: any, keys = Object.keys(obj), n = keys.length, newObj = {};
    while (n--) {
        key = keys[n];
        newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
}

Helper.displayGender = (data: string): string => {
    switch (data) {
        case "0": {
            return "Other"
        }
        case "1": {
            return "Male"
        }
        case "2": {
            return "Female"
        }
        default: {
            return Helper.upperFirstLetter(data)
        }
    }
}

Helper.displayPregnant = (data: string): string => {
    return data == "1" ? "Yes" : "No";
}

export default Helper;