import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  bottom: 0;
  @media (max-width: 375px){
    margin: 3px;
    font-size: 10px;
  }

  @media (max-width: 240px){
    margin: 3px;
    font-size: 7px;
  }
`;

export default function BottomBar() {
  return (
    <Wrapper>© 2023 - PRIMAS GROUP</Wrapper>
  )
}
