import styled from "styled-components";
import React from "react";

export default function BackgroundModal({ color }) {
  const BackgroundColor = styled.span`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${color};
    z-index: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  `;

  return <BackgroundColor id={color}></BackgroundColor>;
}
