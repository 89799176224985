import React, { useEffect, useState } from "react";
import CardResult from "./CardResult";
import styled from "styled-components";
import { Flex } from "components/shared/Flex";
import DescriptionModal from "components/VitalDescriptionModal/DescriptionModal";
import { description, descriptionTitle, fullName, unit } from "constant/setting/VitalDescription";
import { UNKNOWN_DATA, rangeCheckingVitalSign } from "constant/setting/VitalConstraint";

const Card = styled(Flex)`
  background-color: #f8f9ff;
  width: 12rem;
  height: 8.5rem;
  max-height: 300px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  border-radius: 10px;

  @media (max-width: 480px){
    width: 10rem;
    height: 8rem;
  }

  @media (max-width: 375px){
    width: 10rem;
    height: 7rem;
  }

  @media (max-width: 320px){
    width: 8rem;
    height: 5.5rem;
  }
  
  @media (max-width: 240px){
    margin: 2.5px;
    width: 5rem;
    height: 3rem;
  }
`;

const ClickableWrapper = styled.span`
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export default function VitalSignCard({ vitalSign, data }) {
  const [result, setResult]: any = useState();
  //resolve the data
  //check the status
  useEffect(() => {
    var tempObj: any = {};
    tempObj["data"] = data == UNKNOWN_DATA.NaN ? "--" : data;
    tempObj["status"] = rangeCheckingVitalSign(vitalSign, data);
    tempObj["unit"] = unit[`${vitalSign}`];
    tempObj["resultTitle"] = fullName(vitalSign);
    tempObj["descriptionTitle"] = descriptionTitle(vitalSign, tempObj["status"]);
    tempObj["description"] = description(vitalSign);

    setResult(tempObj);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      {result && (
        <>
          <Card>
            <ClickableWrapper onClick={handleOpen}>
              <CardResult
                vitalSign={vitalSign}
                status={result.status}
                resultTitle={result.resultTitle}
                result={result.data}
                unit={result.unit}
              />
            </ClickableWrapper>
          </Card>
          <DescriptionModal
            vitalSign={vitalSign}
            flag={open}
            setFlag={setOpen}
            status={result.status}
            desTitle={result.descriptionTitle}
            description={result.description}
            resultTitle={result.resultTitle}
            result={result.data}
            unit={result.unit}
          />
        </>
      )}
    </>
  );
}