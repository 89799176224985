import { Flex } from "components/shared/Flex";
import { useCameras } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import UAParser from "ua-parser-js";
import PrimasMonitor from "../../components/VitalMeasuringScreen/PrimasMonitor";
import SettingsBars from "../../components/VitalMeasuringScreen/SettingsBars";


const Wrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

function Measure() {
  const cameras = useCameras();
  const [openSettings, setOpenSettings] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(UAParser(navigator.userAgent).device.type === "mobile");

  //A callback used to handle event update License when it applied
  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  //A callback used to handle event when clicking into a settingbutton
  const handleSettingsClick = useCallback(() => {
    setOpenSettings(true);
  }, []);

  //A callback used to handle event when closing setting navigation bar.
  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setOpenSettings(false);
  }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      // Do something with the stream, such as displaying it in a video element
      const video = document.querySelector('video');
      video.srcObject = stream;
      // video.play();
    })
    .catch(error => {
      // Handle errors, such as the user denying permission
      console.error('Error accessing camera:', error);
    });

  return (
    <Wrapper>
      <Container>
        <SettingsBars open={openSettings} onClose={handleCloseSettings} cameras={cameras} isLicenseValid={isLicenseValid} />
        <PrimasMonitor showMonitor={!(true && openSettings)} cameraId={cameraId} onLicenseStatus={updateLicenseStatus} onSettingsClick={handleSettingsClick} />
      </Container>
    </Wrapper>
  );
}

export default Measure;