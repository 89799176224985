import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Flex } from "components/shared/Flex";

const Wrapper = styled(Flex)`
  width: 95%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 15px; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  @media (max-width: 360px) {
    height: 80%;
  }
`;

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <>
      <Wrapper>
        <Typography
          sx={{
            position: "absolute",
            zIndex: 4,
            fontSize: "20px",
            "@media (max-width: 500px)": {
              fontSize: "14px",
            },
            "@media (max-width: 360px)": {
              fontSize: "10px",
            },
          }}
          variant="body2"
          color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
        <LinearProgress
          sx={{
            borderRadius: "15px",
            border: "1px solid #f7f9ff",
            width: "100%",
            height: "100%",
            position: "relative",
            background: "#FFF",
            "& .MuiLinearProgress-bar1Determinate": {
              background: "linear-gradient(90deg, rgba(175,255,63,1) 0%, rgba(179,231,107,1) 25%, rgba(142,199,61,1) 50%, rgba(126,201,83,1) 75%, rgba(110,202,105,1) 100%, rgba(0,212,255,1) 100%)",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Wrapper>
    </>
  );
}

var timer: any;
export default function Timer({ started, durationSeconds }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (started) {
       timer = setInterval(() => {
        setProgress((prev) => prev + 100 / (durationSeconds - 1));
      }, 1000);
      return () => clearInterval(timer);
    } else {
      clearInterval(timer);
      if (Math.round(progress) < 100) {
        window['Finished'] = false;
        setProgress(0);
      } else if (Math.round(progress) >= 100) {
        window['Finished'] = true;
        setProgress(100);
      }
    }
  }, [started]);

  return (
    <LinearProgressWithLabel value={progress} />
  );
}
