export const API_URL = process.env.REACT_APP_API_SETTING;
export const API_RPM_URL = process.env.REACT_APP_API_CRM_SETTING;

export const API_PATH = {
    //CALLFLOW
    //GET - with params
    getSettingByKey: `${API_URL}/settingproxy/proxy/commonservice/settings`,
    //POST - with body
    addKeyPairValue: `${API_URL}/settingproxy/proxy/screenpop/callerdata`,
    //GET - with params
    getKeyPairValue: `${API_URL}/settingproxy/proxy/screenpop/keypair`,
    //GET - with params
    validation: `${API_URL}/settingproxy/validation/screenpop/keypair`,
    //GET - with params
    validationCompleted: `${API_URL}/settingproxy/validation/completed`,

    //VITALRPM
    //PrimasCRM
    //GET - with params
    getSettingByKeyRPM: `${API_RPM_URL}/api/VitalSettingManagement/GetSettingByKey`,
    //POST - with body
    addKeyPairValueRPM: `${API_RPM_URL}/api/VitalIVRKeyPairManagement/SaveIVRKeyPair`,
    //GET - with params
    getKeyPairValueRPM: `${API_RPM_URL}/api/VitalIVRKeyPairManagement/GetCallerInfo`,
}