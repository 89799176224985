import CryptoJS from 'crypto-js'

export default class Encrypter {
    encrypt(clearText: string) {
        var ciphertext = CryptoJS.AES.encrypt(clearText, process.env.REACT_APP_CIPHER_KEY).toString();
        return ciphertext;
    }

    dencrypt(encryptedText: string) {
        var bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_CIPHER_KEY);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    }
}