import Fade from '@mui/material/Fade';
import { Flex } from 'components/shared/Flex';
import { MeasuringText } from 'constant/setting/VitalDescription';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

const Wrapper = styled(Flex)`
    height: 3rem;
    width: 100%;
    color: #646c7e;
    position: relative;
    justify-content: center;
    align-items: center;
`;

var intervalId: any;
export default function TransitionText({ isMeasuring }: any) {
    const [index, setIndex] = useState(-1);
    const [delayTime] = useState((window["time"] / MeasuringText.length) * 1000);

    useEffect(() => {
        setIndex(0);
    }, [isMeasuring])

    useEffect(() => {
        intervalId = setInterval(() => {
            setIndex(index => index + 1);
        }, delayTime);
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <Wrapper>
            <Fade in={true}>
                <div>
                    {index < MeasuringText.length ? MeasuringText[index] : (clearTimeout(intervalId), MeasuringText[MeasuringText.length - 1])}
                </div>
            </Fade>
        </Wrapper>
    )
}