import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import ErrorIcon from "../../assets/error-icon.svg";
import { ErrorCodeDetail } from "constant/setting/VitalDescription";


const Wrapper = styled(Flex)`
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 5vh;
  height: 10vh;
  width: 75%;
  align-items: center;
  background-color: #fee7e7;
  border-radius: 20px;
  border: 0.35vh solid #ff0000;

  z-index: 15;
`;

const Message = styled.div`
  padding: 5px;
  font-size: 2vh;
  font-weight: 600;
  color: #c70808;
  text-align: center;
`;

const Icon = styled.img`
  height: 6vh;
  margin-left: 10px;
`;

const ErrorAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <Icon src={ErrorIcon} />
      <Message>{ErrorCodeDetail[`${message.split(" ").pop()}`]}</Message>
    </Wrapper>
  );
};
export default ErrorAlert;
