import { Flex } from 'components/shared/Flex';
import React from 'react'
import styled from 'styled-components';
import red_flag from "../../assets/icon/review/red-flag.svg";

const IsabelResultBox = styled(Flex)`
  width: 11rem;
  font-size: 0.85rem;
  column-gap: 0.25rem;

  @media (max-width: 480px){
    width: 9.5rem;
    font-size: 0.7rem;
  }

  @media (max-width: 320px){
    width: 7.5rem;
    font-size: 0.55rem;
  }
  
  @media (max-width: 240px){
    width: 4.5rem;
    font-size: 0.3rem;
  }
`;

const ConditionLink = styled.a`
  color: #000000;
  text-decoration: none;
  font-weight: 600;
`

const Flag = styled.img`
  width: 1.2rem;
  height: 1.2rem;

  @media (max-width: 480px){
    width: 0.7rem;
    height: 0.7rem;
  }

  @media (max-width: 320px){
    width: 0.55rem;
    height: 0.55rem;
  }

  @media (max-width: 240px){
    width: 0.3rem;
    height: 0.3rem;
  }
`;

const Common = styled.span`
  //level
  border-radius: 50px;
  padding: 0.125rem 0.5rem;
  color: white;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  //common
  background-color: #C1FAE2;
  color: #00D681;
  position: relative;
  font-weight: 600;

  @media (max-width: 480px){
    border-radius: 0.5rem;
    height: 0.7rem;
    font-size: 0.7rem;
    padding: 0.125rem 0.3rem;
  }

  @media (max-width: 320px){
    border-radius: 0.5rem;
    height: 0.55rem;
    font-size: 0.55rem;
    padding: 0.2rem 0.3rem;
  }
  @media (max-width: 240px){
    border-radius: 0.5rem;
    height: 0.3rem;
    font-size: 0.3rem;
    padding: 0.125rem 0.2rem;
  }
`;

export default function IsabelResult({ condition }: any) {

  const openNewWindow = (e) => {
    e.preventDefault();
    window.open(`https://vsearch.nlm.nih.gov/vivisimo/cgi-bin/query-meta?v%3Aproject=medlineplus&query=${condition.name}`, '_blank', 'location=yes,scrollbars=yes,status=yes')
  }

  return (
    <IsabelResultBox>
      {/* <span>{condition.name}</span> */}
      <ConditionLink onClick={openNewWindow} href='#'>{condition.name}</ConditionLink>
      {
        (condition.red_flag == "true") &&
        <Flag src={red_flag}></Flag>
      }
      {
        (condition.common == "true") &&
        <Common>C</Common>
      }
    </IsabelResultBox >
  )
}
