import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import WarningIcon from "../../assets/icon/warning.svg";
import { ErrorCodeDetail } from "constant/setting/VitalDescription";

const Wrapper = styled(Flex)`
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 5vh;
  height: 10vh;
  width: 75%;
  align-items: center;
  background-color: #fff7df;
  border-radius: 20px;
  border: 0.35vh  solid #ffbf00;

  z-index: 4;
  word-wrap: break-word;
  z-index: 15;
`;

const Message = styled.div`
  padding: 5px;
  font-size: 2vh;
  font-weight: 600;
  color: #dbac20;
  text-align: center;
`;

const Icon = styled.img`
  height: 6vh;
  margin-left: 10px;
`;

const WarningAlert = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Wrapper>
      <Icon src={WarningIcon} />
      <Message>{ErrorCodeDetail[`${message.split(" ").pop()}`]}</Message>
    </Wrapper>
  );
};
export default WarningAlert;
