export const API_PARAM_KEY = {
  binahLicense: process.env.REACT_APP_BINAH_LICENSE,
  binahDuration: process.env.REACT_APP_BINAH_DURATION,
}

export const SOURCE_FLAG = {
  sourceFlag: process.env.REACT_APP_SOURCE_FLAG,
  environmentFlag: process.env.REACT_APP_ENVIRONMENT,
}

export const FLAG_VALUE = {
  true: "true",
  false: "false",
  vitalRPM: "VITALRPM",
  callFlow: "CALLFLOW",
  dev: "DEV",
  production: "PRODUCTION"
}

export const SOURCE_PARAM = {
  tokenParam: process.env.REACT_APP_BYPASS_KEY,
  iframeParam: process.env.REACT_APP_IFRAME_KEY,
}

export const PARAM_VALUE = {
  true: "true",
  false: "false",
}

export const ENV_VALUE = {
  version: process.env.REACT_APP_VERSION,
}