import React from 'react'
import styled from 'styled-components';

import primas from '../../assets/primas/primas.svg'

const Img = styled.img`
  position: absolute;
  height: 70%;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function Logo() {
  return (
    <Img src={primas} />
  );
}
